import {Injectable} from '@angular/core';
import {HttpClient}from '@angular/common/http';
import { Globals } from 'app/global';
import { Login } from '../model/login';
import { DatePipe } from '@angular/common';
import { PurchaseOrderStockIn } from '../model/purchaseorder';

@Injectable({
    providedIn:'root'
})

export class RoleService{

    constructor(private httpclient:HttpClient,private global:Globals,private datePipe:DatePipe){}

 
    addRole(role) {
        debugger;
        return this.httpclient.post(this.global.G_apiUrl + "/add-all/role",role)
    }
    
}
