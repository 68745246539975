import{Injectable}from '@angular/core';
import { loginData } from './service/model/login';
Injectable()
export class Globals
{

    //  G_apiUrl = "http://192.168.0.9:9091/ERPSystem";

     G_apiUrl = "http://mechtrotechapi.artcode.in/";
  
    loggedUser:loginData=new loginData();     
}

