import { Component, OnInit } from '@angular/core';
import { Login } from 'app/service/model/login';
import { LoginService } from 'app/service/apiservice/login.service';
import { Router } from '@angular/router';
import { Globals } from 'app/global';
import { NgxSpinnerService } from 'ngx-spinner';
import { Role } from '../service/model/role';
import { RoleService } from '../service/apiservice/role.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  login: Login = new Login();


  constructor(private loginService: LoginService, private router: Router,
    private global: Globals, private spinner: NgxSpinnerService, private roleService: RoleService) { }

  ngOnInit() {
    this.setRole();
  }



  LoginSubmit() {
    debugger;
    this.spinner.show();
    // if (this.login.userName == 'admin' && this.login.password == 'admin') {
    //   // Object.assign(this.global.loggedUser,response);
    //   //       localStorage.setItem('roleId',this.login.roleId.toString());
    //   //       localStorage.setItem('userdetail',JSON.stringify(response));
    //   this.router.navigate(['/dashboard']);
     
    //   this.spinner.hide();
    // } else {
    //   alert("Invaild Login UserName And Password");
    //   this.spinner.hide();
    // }
    this.loginService.Login(this.login)
      .subscribe((response) => {
        if (response) {
          // console.log(response);
          Object.assign(this.global.loggedUser,response);
          localStorage.setItem('userId',response['userId']);
          // localStorage.setItem('userdetail',JSON.stringify(response));
          this.router.navigate(['/dashboard']);
          this.spinner.hide();
        }
      },
        error => {
          alert("Invalid Username And Password");
          this.spinner.hide();
        })

  }
  setRole() {




  }

}
