import { Component, OnInit } from '@angular/core';

declare const $: any;
declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}
export const ROUTES: RouteInfo[] = [
 
  { path: '/dashboard', title: 'Dashbord', icon: 'content_paste', class: '' },
  { path: '/purchaseorder', title: 'PurchaseOrder', icon: 'content_paste', class: '' },
  { path: '/stocklist', title: 'StockIn', icon: 'content_paste', class: '' },
  {path:'/rdcstocklist',title:'RDCStockIn',icon: 'content_paste', class: '' },
  { path: '/stockoutlist', title: 'StockOut', icon: 'content_paste', class: '' },
  {path:'/stockReportlist', title: 'Stock Report', icon: 'content_paste', class: '' },
  { path: '/supplier', title: 'Supplier', icon: 'content_paste', class: '' },
  { path: '/vendor', title: 'Vendor', icon: 'content_paste', class: '' },
  { path: '/employee', title: 'Employee', icon: 'content_paste', class: '' },
  { path: '/job', title: 'Job', icon: 'content_paste', class: '' },
  // { path: '/addchallan', title: 'Challan', icon: 'content_paste', class: '' },
  // { path: '/quotation', title: 'Quotation', icon: 'content_paste', class: '' },
{path:'/item', title: 'Item', icon: 'content_paste', class: '' } ,
{path:'/openbalance', title: 'Open Balance', icon: 'content_paste', class: '' },


];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];

  stockInItems:any[];
  stockInTitle:string;
  stockInIcon:string;

  rdcstockInItems:any[];
  rdcstockInTitle:string;
  rdcstockInIcon:string;


  stockOutItems:any[];
  stockOutTitle:string;
  stockOutIcon:string;

  purchaseOrderItems:any[];
  purchaseOrderTitle:string;
  purchaseOrderIcon:string;

  itemMenus: any[];
  itemTitle: string;
  itemIcon: string;

  constructor() { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    this.stockInItems= new Array(
      { path: '/stockin', title: 'Add', icon: 'content_paste', class: '' },
      { path: '/stocklist', title: 'View', icon: 'content_paste', class: '' }, );
    this.stockInIcon="content_paste";
    this.stockInTitle="Stock In";


    this.rdcstockInItems= new Array(
      { path: '/rdcstockin', title: 'Add', icon: 'content_paste', class: '' },
      { path: '/rdcstocklist', title: 'View', icon: 'content_paste', class: '' }, );
    this.rdcstockInIcon="content_paste";
    this.rdcstockInTitle="RDC Stock In";


    this.stockOutItems= new Array(
      { path: '/stockout', title: 'Add', icon: 'content_paste', class: '' },
      { path: '/stockoutlist', title: 'View', icon: 'content_paste', class: '' },);
    this.stockOutIcon="content_paste";
    this.stockOutTitle="Stock Out";

    this.purchaseOrderItems= new Array(
      { path: '/purchaseorderadd', title: 'Add', icon: 'content_paste', class: '' },
      { path: '/purchaseorder', title: 'View', icon: 'content_paste', class: '' },);
    this.purchaseOrderTitle="Purchase Order";
    this.purchaseOrderIcon="content_paste";

    this.itemMenus = new Array(
      { path: '/item', title: 'Item', icon: 'content_paste', class: '' },
      { path: '/itemrate', title: 'ItemRate', icon: 'content_paste', class: '' },
      // { path: '/itemcategory', title: 'ItemCategory', icon: 'content_paste', class: '' },
      
    );

    this.itemIcon = "content_paste";
    this.itemTitle = "Item";





    // this.reportItems= new Array(
    //   { path: '/flatstatement', title: 'Flat Statement', icon: 'content_paste', class: '' },
    //   { path: '/incomereport', title: 'Income Report', icon: 'content_paste', class: '' },
    //   { path: '/expensesreport', title: 'Expenses Report', icon: 'content_paste', class: '' },
    //   { path: '/invoicereport', title: 'Invoice Report', icon: 'content_paste', class: '' },
    //   { path: '/duereport', title: 'Due Report', icon: 'content_paste', class: '' },
      
    // );
  }
  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  };
}
