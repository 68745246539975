import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http'


import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';

import { AppComponent } from './app.component';

import {
  AgmCoreModule
} from '@agm/core';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { Globals } from './global';
import { LoginComponent } from './login/login.component';
import { LoginLayoutModule } from  './login/login.module';
import { NgxSpinnerModule } from "ngx-spinner";
import { LocationStrategy, HashLocationStrategy, DatePipe } from '@angular/common';

import { ExpenseComponent } from './tabs/expense/expense/expense.component';
import { JobexpenseComponent } from './tabs/jobexpense/jobexpense/jobexpense.component';



@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    ComponentsModule,
    RouterModule,
    AppRoutingModule,
    HttpClientModule,
    LoginLayoutModule,
    AgmCoreModule.forRoot({
      apiKey: 'YOUR_GOOGLE_MAPS_API_KEY'
    }),
    NgxSpinnerModule
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    LoginComponent,
   
    ExpenseComponent,
    JobexpenseComponent,
  ],
  providers: [ DatePipe,Globals,{provide:LocationStrategy,useClass:HashLocationStrategy}],
  bootstrap: [AppComponent],
  
})
export class AppModule { }
