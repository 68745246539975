import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Globals } from 'app/global';
import { Login } from '../model/login';

@Injectable({
    providedIn: 'root'
})

export class LoginService {

    constructor(private httpclient: HttpClient, private global: Globals) { }

    // Login(login:Login)
    // {
    //     return this.httpclient.post(this.global.G_apiUrl+"/login?type=3",
    //     {
    //         "mobile":login.userName,
    //         "password":login.password,

    //     });
    // }
    Login(login: Login) {






        // const httpParams = new HttpParams()
        //     .set('username', login.userName)
        //     .set('password', login.password)
        // {
        //     "mobile": login.userName,
        //     "password": login.password,
        // { params: httpParams }
        // }
        debugger
        return this.httpclient.post(this.global.G_apiUrl + "/login?username="+login.userName+"&password="+login.password,'',);
    }

    getRoleList() {
        return this.httpclient.get(this.global.G_apiUrl + "/building");
    }
}