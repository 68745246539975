export class Login {
    userName: string;
    password: string;
    roleId:number;
}

export class loginData {
    adminId: number;
    createdAt: string;
    email: string;
    mobile: string;
    name: string;
    password: string;
    pic: string;
    status: number;
    updatedAt: string;
}

export class changepassword
{
    adminId:number;
    newPassword:string;
    oldPassword: string;
}